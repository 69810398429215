@import './theme/colors';
@import './theme/variable';

html {
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;

}
body {
  color: $TEXT;
  min-height: 100vh;
  font-family: 'Microsoft JhengHei';
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form-group {
  width: 100%;
  display: inline-flex;
  align-items: center;
  margin-bottom: 12px;
  label {
    text-align: left;
    font-size: 16px;
    min-width: 90px;
    font-weight: bold;
    margin-bottom: 8px;
  }
}

.input {
  flex: 1;
  outline: none;
  height: 36px;
  margin-left: 8px;
  margin-bottom: 8px;
  padding-left: 8px;
  border-radius: 8px;
  border: 1px solid #ddd;
  font-size: 18px;
  color: #304a79;
}

.footer {
  position: absolute;
  bottom: 0px;
}

@mixin answer {
  color: #fff;
  width: 25px;
  display: inline-block;
  height: 25px;
  border-radius: 20px;
  line-height: 25px;
  margin: 2px;
  font-weight: bolder;
}

.no {
  background-color: #ec4242;
  @include answer();
}

.yes {
  background-color: #64c16f;
  @include answer();
}

.score-with {
  width: 10px;
}

h2 {
  margin: 0px;
  padding: 15px;
}

a {
  text-decoration: none;
}