$WHITE: #fff;
$TEXT: #3e4e6e;
$PRIMARY: #4AA9DB;
$PRIMARY_DARK: #2094d2;
$SECONDARY: #647DE2;
$LOGO_TEXT: #CDE0FD;
$MENU_TEXT: #3273b7;
$TEXT_GREY: #838fa0;
$LAYOUT_BACKGROUND: #ebf2f9;

$DANGER: #ff5a7a;
$DANGER_SECONDARY: #ce2626;

$WARNING: #ffab5a;
$WARNING_SECONDARY: #f56b29;

$SUCCESS: #88d839;
$SUCCESS_SECONDARY: #2cbfa4;

$INFO: #32dac8;
$INFO_SECONDARY: #5db3dc;
