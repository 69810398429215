@mixin background($url) {
  background-image: url($url);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

@mixin box($width: 50px, $height: 50px, $position: absolute) {
  width: $width;
  height: $height;
  position: $position;
}

@mixin before() {
  position: relative;
  &::before {
    position: absolute;
    content: '';
    @content;
  }
}

@mixin after() {
  position: relative;
  &::after {
    position: absolute;
    content: '';
    @content;
  }
}

@mixin gradient-text($start: $PRIMARY, $end: $SECONDARY) {
  color: $start;
  background: -webkit-linear-gradient( $start, $end);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
